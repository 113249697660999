import * as React from 'react';

import { StripeProvider as StripeElementsProvider } from 'react-stripe-elements';

declare global {
  interface Window {
    Stripe: stripe.Stripe;
  }
}

const StripeProvider: React.FC<{}> = ({ children }) => {
  const [stripe, setStripe] = React.useState<stripe.Stripe | null>(null);

  React.useEffect(() => {
    // Inject a script tag manually
    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () =>
      setStripe(
        window.Stripe(
          process.env.STRIPE_PUBLIC_API_KEY || 'stripe-key-not-defined'
        )
      );
    document.body && document.body.appendChild(stripeJs);
  }, []);

  return (
    <StripeElementsProvider stripe={stripe}>{children}</StripeElementsProvider>
  );
};

export default StripeProvider;
